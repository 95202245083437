import { BaseEnum } from './BaseEnum'

export enum EnChapterSetting {
  MAX_WIDTH = 'maxWidth',
  FONT_SIZE = 'fontSize',
  TEXT_INDENT = 'textIndent',
  LINE_HEIGHT = 'lineHeight',
  TEXT_ALIGN = 'textAlign',
}

export class ChapterSettingEnum extends BaseEnum<EnChapterSetting> {
  static getLabels() {
    return {
      [EnChapterSetting.MAX_WIDTH]: 'Container width',
      [EnChapterSetting.FONT_SIZE]: 'Font size',
      [EnChapterSetting.TEXT_INDENT]: 'Text indent',
      [EnChapterSetting.LINE_HEIGHT]: 'Line height',
      [EnChapterSetting.TEXT_ALIGN]: 'Text align',
    }
  }
}
