import { BaseEnum } from './BaseEnum'

export enum EnBookOrderBy {
  NEW = 'id',
  UPDATE = 'newUploadAt',
  LIKES = 'likes',
  BOOKMARKS = 'bookmarks',
}

export class BookOrderByEnum extends BaseEnum<EnBookOrderBy> {
  static getLabels() {
    return {
      [EnBookOrderBy.NEW]: 'By new',
      [EnBookOrderBy.UPDATE]: 'By updates',
      [EnBookOrderBy.LIKES]: 'By likes',
      [EnBookOrderBy.BOOKMARKS]: 'By bookmarks',
    }
  }
}
