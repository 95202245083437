import clsx from 'clsx'
import { Icon } from 'src/components/common/Icon'

export const BlurOverImage = (props: { className?: string }) => {
  return (
    <div
      className={clsx(
        'inset-0 border dark:border-black-300 border-gray-400 grid items-center justify-center dark:text-gray-75 absolute !bg-opacity-[0.95] dark:bg-black-600 bg-gray-300',
        props.className,
      )}
    >
      <div className="grid justify-items-center">
        <Icon name="lock" />
      </div>
    </div>
  )
}
