'use client'

import clsx from 'clsx'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { memo, useEffect, useMemo, useState } from 'react'
import { t } from 'src/helpers/translate.helper'

dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.locale('ru')

export const Date = memo(function Date(props: {
  date: Date
  className?: string
  isFromNow?: boolean
}) {
  const [date, setDate] = useState<Date | null>(null)

  useEffect(() => {
    setDate(props.date)
  }, [props.date])

  const hours = useMemo(
    () => (date ? dayjs().diff(dayjs(date), 'hours') : null),
    [date],
  )
  const colorClass = useMemo(() => {
    if (hours === null) {
      return ''
    }

    if (hours < 1) {
      return 'text-green-500'
    }

    if (hours < 24) {
      return 'text-primary'
    }

    return 'text-gray-primary'
  }, [hours])

  const inlineDate = useMemo(() => {
    if (hours === null) {
      return ''
    }

    if (props.isFromNow || hours < 72) {
      const inlineDate = dayjs(props.date).utc().local().fromNow()
      if (inlineDate === t('some seconds ago')) {
        return t('less then a minute')
      }
      return inlineDate
    }

    return dayjs(props.date).utc().local().format('DD.MM.YYYY')
  }, [hours, props.date, props.isFromNow])

  return (
    <time className={clsx('text-tiny h-[21px]', colorClass, props.className)}>
      {inlineDate}
    </time>
  )
})
