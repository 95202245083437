import { EnNotificationType } from '@prisma/client'
import { BaseEnum } from './BaseEnum'

export class NotificationTypeEnum extends BaseEnum<EnNotificationType> {
  static getLabels() {
    return {
      [EnNotificationType.BOOK]: 'Updates',
      [EnNotificationType.SOCIAL]: 'Comments',
      [EnNotificationType.IMPORTANT]: 'Systems',
    }
  }
}
