import { Book, EnBookmarkType, EnTagType, Tag } from '@prisma/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { getCatalogUrl } from 'src/helpers/global.helper'
import { t } from 'src/helpers/translate.helper'
import { isHentai, isRanobe } from './main.util'
dayjs.extend(utc)

export const getBookImage = (
  book: Book,
  priority: 'imageLow' | 'imageMid' | 'imageHigh' = 'imageHigh',
) => {
  const url = book[priority] || book.imageLow || book.imageMid || book.imageHigh

  if (!url) {
    return ''
  }

  return `${process.env.NEXT_PUBLIC_ENV_MEDIA_DOMAIN}${url}`
}

export const getBookTitle = (book: Book) => book.title || book.titleEn || '...'

export const getBookUrl = (slug: string, branchId?: number | null) =>
  `${getCatalogUrl()}/${slug}${branchId ? `?branchId=${branchId}` : ''}`

export const getSlideUrl = (link: string) =>
  `${process.env.NEXT_PUBLIC_ENV_MEDIA_DOMAIN}${link}`

export const getBookmarkClass = (type: EnBookmarkType | null) => {
  switch (type) {
    case EnBookmarkType.ABANDONED:
      return 'text-red-600 dark:text-red-700'

    case EnBookmarkType.COMPLETED:
      return 'text-blue-600 dark:text-blue-700'

    case EnBookmarkType.FAVORITE:
      return 'text-fuchsia-600 dark:text-fuchsia-700'

    case EnBookmarkType.PLAN:
      return 'text-violet-600 dark:text-violet-700'

    case EnBookmarkType.READ:
      return 'text-emerald-600 dark:text-emerald-700'

    default:
      return 'text-gray-200 dark:text-black-600'
  }
}

export const getTitleMeta = async (
  titleRu: string | null,
  titleEn: string | null,
) => {
  const sub = isRanobe() ? 'Ranobe' : 'Manga'

  titleRu = titleRu ? `${titleRu} ${t(sub).toLocaleLowerCase()}` : null
  titleEn = titleEn ? `${titleEn} ${sub.toLocaleLowerCase()}` : null

  return titleEn
    ? [titleRu, titleEn].filter(Boolean).join(' | ')
    : titleRu || t('Title not found')
}

export const getDescriptionMeta = async ({
  titleRu,
  titleEn,
  description,
  robotDescription,
}: {
  titleRu: string | null
  titleEn: string | null
  description: string | null
  robotDescription: string | null
}) => {
  const sub = t(isRanobe() ? 'Ranobe' : isHentai() ? 'Hentai manga' : 'Manga')

  if (robotDescription) {
    return robotDescription
  }

  let metaDescription = ''
  if (titleRu && titleRu.length) {
    metaDescription = `${sub} ${titleRu}`
  }

  if (titleEn && titleEn.length) {
    const descriptionTitleEn = `${sub} ${titleEn}`
    metaDescription = metaDescription.length
      ? `${metaDescription} / ${descriptionTitleEn}`
      : descriptionTitleEn
  }

  // FIXME: create reg for it
  description = description
    ? description
        .replace(/(<\/?(?:span|div)[^>]*>)|<[^>]+>/gi, '$1')
        .replace(/&nbsp;/g, ' ')
        .replace(/&ldquo;/g, '')
        .replace(/&amp;/g, '')
        .replace(/\s(?=(\n|\r|\r\n))/g, '')
        .replace(/&quot;/g, '')
        .replace(/&rdquo;/g, '')
        .trim()
    : ''

  metaDescription = metaDescription + '. ' + description
  if (metaDescription.length > 350) {
    metaDescription = metaDescription.slice(0, 350) + '...'
  }
  return metaDescription
}

export const isAvailableByLocation = (
  isClientRu: boolean,
  isBookAccessRu: boolean,
) => (isClientRu ? isBookAccessRu : true)

export const getTagLink = (tag: Tag) =>
  tag.type === EnTagType.COLLECTION
    ? `${getCatalogUrl()}?serie=${tag.serieId}&persons=${tag.id}`
    : `${getCatalogUrl()}?tags=${tag.id}`

export const isBlur = (isAuth: boolean, book: Book) =>
  isHentai() ? (isAuth ? false : true) : false //!book.isSafe
