'use client'

import { ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { memo } from 'react'
import { Icon } from 'src/components/common/Icon'
import { t } from 'src/helpers/translate.helper'

export const LoadMore = memo(function LoadMore(props: {
  className?: string
  isLoading: boolean
  onClick?: () => void
}) {
  return (
    <div
      className={clsx(
        'grid w-16 m-auto place-content-center text-primary hover:text-hover outline-none',
        props.className,
      )}
      aria-label={t('More')}
      title={t('More')}
      onClick={props.onClick}
    >
      {!props.isLoading && (
        <ChevronDownIcon className="animate-bounce !w-8 !h-8" />
      )}

      {props.isLoading && (
        <Icon className="!w-8 !h-8 animate-spin" name="shuriken" />
      )}
    </div>
  )
})
